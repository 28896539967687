import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export class CustomValidators {

    static birthYear(c: FormControl): ValidationErrors {
        const numValue = Number(c.value);
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 85;
        const maxYear = currentYear - 18;
        const isValid = !isNaN(numValue) && numValue >= minYear && numValue <= maxYear;
        const message = {
            'years': {
                'message': 'O ano deve ser um número válido entre ' + minYear + ' e ' + maxYear
            }
        };
        return isValid ? null : message;
    }

    static dateLessThan(from: string, to: string) {
        return (group: FormGroup): { [key: string]: any } => {

            const f = group.controls[from];
            const t = group.controls[to];

            if (!t.value && !f.value) { return null; }

            const isValid = t.value > f.value;
            const message = {
                dateLessThan: {
                    message: 'A data inicial deve ser menor que a data final'
                }
            };
            return isValid ? null : message;
        };
    }

    static dateEqualThan(from: string, to: string) {
        return (group: FormGroup): { [key: string]: any } => {

            const f = group.controls[from];
            const t = group.controls[to];

            if (!t.value && !f.value) { return null; }

            const isValid = t.value === f.value;
            const message = {
                dateLessThan: {
                    message: 'A data inicial deve ser igual a data final'
                }
            };
            return isValid ? null : message;
        };
    }

    static dateLessOrEqualThan(from: string, to: string) {
        return (group: FormGroup): { [key: string]: any } => {

            const f = group.controls[from];
            const t = group.controls[to];

            if (!t.value && !f.value) { return null; }

            const isValid = t.value > f.value;
            const message = {
                dateLessThan: {
                    message: 'A data inicial deve ser menor ou igual a data final'
                }
            };
            return isValid ? null : message;
        };
    }

    static countryCity(form: FormGroup): ValidationErrors {
        const countryControl = form.get('country');
        const cityControl = form.get('city');

        if (countryControl != null && cityControl != null) {
            const country = countryControl.value;
            const city = cityControl.value;
            let error = null;

            if (country === 'France' && city !== 'Paris') {
                error = 'If the country is France, the city must be Paris';
            }

            const message = {
                'countryCity': {
                    'message': error
                }
            };

            return error ? message : null;
        }
    }

    static email(c: FormControl): ValidationErrors {
        // tslint:disable-next-line:max-line-length
        const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(c.value);
        const message = {
            'email': {
                'message': 'Email invalido!!'
            }
        };
        return isValidEmail ? null : message;
    }

    static passwordSize(c: FormControl): ValidationErrors {
        if (!c.value) { return; }
        const isValidPasswordSize = c.value.length > 8;
        const message = {
            'passwordSize': {
                'message': 'A senha deve ter no minimo 8 caracteres!!'
            }
        };
        return isValidPasswordSize ? null : message;
    }

    static passwordConfirming(c: FormControl): ValidationErrors {
        // if(!c) return;
        const isValidPassword = c.get('password').value === c.get('confirmedPassword').value;
        const message = {
            'passwordConfirming': {
                'message': 'As senhas não correspondem !'
            }
        };
        return isValidPassword ? null : message;
    }

    static uniqueName(c: FormControl): Promise<ValidationErrors> {
        const message = {
            'uniqueName': {
                'message': 'O nome não é único'
            }
        };

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(c.value === 'Existing' ? message : null);
            }, 1000);
        });
    }

    static telephoneNumber(c: FormControl): ValidationErrors {
        const isValidPhoneNumber = /^\d{3,3}-\d{3,3}-\d{3,3}$/.test(c.value);
        const message = {
            'telephoneNumber': {
                'message': 'The phone number must be valid (XXX-XXX-XXX, where X is a digit)'
            }
        };
        return isValidPhoneNumber ? null : message;
    }

    static telephoneNumbers(form: FormGroup): ValidationErrors {

        const message = {
            'telephoneNumbers': {
                'message': 'At least one telephone number must be entered'
            }
        };

        const phoneNumbers = form.controls;
        const hasPhoneNumbers = phoneNumbers && Object.keys(phoneNumbers).length > 0;

        return hasPhoneNumbers ? null : message;
    }

    static noNegative(form: FormGroup): { [key: string]: any; } {
        const message = {
            'noNegative': {
                'message': 'O Valor deve ser positivo!'
            }
        };

        const isValidNumber = Number(form.value) >= 0;

        return isValidNumber ? null : message;
        // if (Number(form.value) < 0) {
        //   return {nonZero: true};
        // } else {
        //   return null;
        // }
    }
}
