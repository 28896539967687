import { Account } from './account';

export class Customer {
    id?: string;
    _id?: string;
    account: Account;
    code: number;
    deliveryTime: string;
    deliveryTimeOption: string;
    deliveryTimeHours: string;
    deliveryTimeCutHour: string;
    deliveryTimeBeforeCutDay: number;
    deliveryTimeBeforeCutHour: string;
    deliveryTimeAfterCutDay: number;
    deliveryTimeAfterCutHour: string;
    tokenNucciIntegration: string;
    cnpj: string;
    logo: string;
    name: string;
    createdAt?: string;
    updatedAt?: string;
    integrations: {
        erp: [
            {
                token: string,
                name: string
            }
        ],
        omie: {
            app_key: string,
            app_secret: string,
        },
        bling: {
            apikey: string
        }
    };
    expeditionControll: [
        {
            deliveryTimeDay: string;
            deliveryTimeOption: {
                orderType: string;
                personalization: boolean;
            };
            deliveryTimeHours: string;
            deliveryTimeCutHour: string;
            deliveryTimeBeforeCutDay: number;
            deliveryTimeBeforeCutHour: string;
            deliveryTimeAfterCutDay: number;
            deliveryTimeAfterCutHour: string;
        }
    ];
    transportCompanys: any;
    conferenceSettings: any;
    webhookVolumes: {
        token: string;
        url: string;
        status: boolean;
    };

    constructor(parameters) {
        Object.assign(this, parameters);
        this.account = new Account(this.account);
    }
}
