import { Pagination } from '@app/shared/models/pagination';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportConferenceService extends BaseService {

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }
    getAll(queryParams?: {
        [key: string]: any;
    }): Observable<Pagination<any>> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<Pagination<any>>(`${this.api}/conference/`, {
                params
            })
            .pipe(catchError(this.handleError<Pagination<any>>('getAll')));
    }

}
