import { Pagination } from '@app/shared/models/pagination';
import { Customer } from '@app/shared/models';
import { Router, ActivatedRoute } from '@angular/router';

import { ReportConferenceService } from './../../../../shared/services/reports/report-conference/report-conference.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conference-report',
  templateUrl: './conference-report.component.html',
  styleUrls: ['./conference-report.component.scss']
})
export class ConferenceReportComponent implements OnInit {
    tags = [];
    perPage = 10;
    query = { per_page: this.perPage };
    response: any;
    conference$: any;
  constructor(private _reportConference: ReportConferenceService) {
   }

  ngOnInit() {
      this._reportConference
        .getAll()
        .subscribe((conferences) => (this.conference$ = conferences));
    }

    //  onSearch(event) {
    //     this._reportConference
    //         .getAll(event)
    //         .subscribe((pagination) => (this.pagination$ = pagination));
    // }

    // // onExport(event) {
    // //     this._orderTagService.export(event).subscribe();
    // // }

    // onPageChange(event) {
    //     this._reportConference
    //         .getAll(event)
    //         .subscribe((pagination) => (this.pagination$ = pagination));
    // }
}
