import { environment, Environment } from 'src/environments/environment';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export abstract class BaseService {
    public api: string;
    public environment: Environment = environment;

    constructor(private alertService: AlertService, public http: HttpClient) {
        this.api = this.environment.API_ENDPOINT;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            const erro = error.error;
            switch (error.status) {
                case 0:
                    this.log(`Erro ao contatar o servidor`, 'alert');
                    break;
                case 422:
                    const arr = [];
                    Object.keys(erro.errors).map(function (key) {
                        arr.push(erro.errors[key].join(','));
                        return arr;
                    });
                    const erros = arr.reduce(function (acc, curr, index) {
                        return index === 0 ? curr : acc + '\n' + curr;
                    });
                    this.log(`${erros}`, 'console');
                    break;
                case 401:
                    this.log(`Acesso Negado, Favor logar novamente`, 'alert');
                    break;
                case 400:
                    if ('message' in erro) {
                        this.log(`${erro.message}`, 'console');
                    } else {
                        this.log(`${erro.error}`, 'console');
                    }
                    break;

                default:
                    this.log(`${operation} failed: ${error.message}`, 'console');
                    break;
            }
            return throwError(error);
            // throwError(error);
        };
    }

    /** Log a HeroService message with the MessageService */
    public log(message: string, type: string = 'alert') {
        if (type === 'alert') {
            this.alertService.error(
                `${this.constructor.name}: ${message}`,
                this.alertService.ANIMATE_FROM_LEFT
            );
        }
        if (type === 'console') {
            console.error(
                `${this.constructor.name}: ${message}`,
                this.alertService.ANIMATE_FROM_LEFT
            );
        }
    }

    printPdf(response: HttpResponse<Blob>) {
        const blob = new Blob([response.body], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);

        iframe.contentWindow.print();
    }

    saveFile(response: HttpResponse<Blob>) {
        const parseFileName = (contentDisposition: string) => {
            if (!contentDisposition) {
                return null;
            }
            const matches = /filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/g.exec(
                contentDisposition
            );
            return matches && matches.length > 1 ? matches[1] : null;
        };
        const fileName = parseFileName(
            response.headers.get('content-disposition')
        );
        const url = window.URL.createObjectURL(response.body);
        const link = document.createElement('a');

        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    }

    downloadXLS(URL, content, nameFile) {
        const httpOption: Object = {
            observe: 'response',
            responseType: 'arraybuffer'
        };

        this.http.post<any>(URL, content, httpOption)
        .pipe(map((res: HttpResponse<any>) => {
            return {
            filename: nameFile + '.xlsx',
            data: new Blob(
                [res['body']],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
            ),
            };
        }))
        .subscribe(res => {
            const link = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = link;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(link);
            a.remove();

        }, error => {
            throw error;
        }, () => {
            console.log('Completed file download.');
        });
    }

    downloadPDF(URL: string, content: any, nameFile: string) {
    const httpOption: Object = {
        observe: 'response',
        responseType: 'arraybuffer'
    };

    this.http.post<any>(URL, content, httpOption)
    .pipe(map((res: HttpResponse<any>) => {
        return {
            filename: nameFile + '.pdf',
            data: new Blob(
                [res.body],
                { type: 'application/pdf' }
            ),
        };
    }))
    .subscribe(res => {
        const link = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = link;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(link);
        a.remove();
    }, error => {
        throw error;
    }, () => {
        this.alertService.success('Completed file download.');
    });
}

}
