import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { Attachment } from '../../models/attachment';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class AttachmentsService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    save(file: File | File[]): Observable<Attachment | Attachment[]> {
        const formData = new FormData();
        Array.isArray(file)
            ? file.forEach(f => formData.append('file', f))
            : formData.append('file', file);

        return this.http
            .post<Attachment | Attachment[]>(
                `${this.api}/attachments`,
                formData
            )
            .pipe(
                catchError(
                    this.handleError<Attachment | Attachment[]>('all', [])
                )
            );
    }

    delete(id) {
        return this.http
            .delete<Attachment>(`${this.api}/attachments/${id}`)
            .pipe(catchError(this.handleError<Attachment>(`delete: ${id}`)));
    }

    // all(): Observable<Invoice[]> {
    //     return this.http
    //         .get<Invoice[]>(`${this.constants.API_ENDPOINT}/invoices?type=entry`)
    //         .pipe(catchError(this.handleError<Invoice[]>('all', [])));
    // }

    // getInvoice(invoiceNumber: number): Observable<Invoice> {
    //     return this.http
    //         .get<Invoice>(
    //             `${this.constants.API_ENDPOINT}/invoices/${invoiceNumber}`
    //         )
    //         .pipe(catchError(this.handleError<Invoice>(`getInvoice number=${invoiceNumber}`)));
    // }

    // updateItem(invoiceNumber: number, item: Item): Observable<Item> {
    //     return this.http.put<Item>(`${this.constants.API_ENDPOINT}/invoices/${invoiceNumber}/item/${item._id}`, item)
    //     .pipe(catchError(this.handleError<Item>(`updateItem number=${invoiceNumber} _id=${item._id}`)));

    // }

    // updateInvoice(invoice: Invoice): Observable<Invoice> {
    //     return this.http.put<Invoice>(`${this.constants.API_ENDPOINT}/invoices/${invoice.number}`, invoice)
    //     .pipe(catchError(this.handleError<Invoice>(`updateInvoice number=${invoice.number}`)));

    // }

    downloadPdf(invoiceNumber: number): Observable<Blob> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Response-Type', 'application/pdf');
        return this.http.get(
            `${this.api}/report/invoices/${invoiceNumber}/entry`,
            {
                responseType: 'blob'
            }
        );
    }

     downloadXML(id: any): Observable<Blob> {
        return this.http
            .get<any>(`${this.api}/attachments/${id}`, {

                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('get'))
            );
    }

    get(id: any, queryParams?:
        {
            [key: string]: any;
        }
    ): Observable<any> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http.get(`${this.api}/attachments/6152199cfda30a00409ac3e0`, {params})
        .pipe(catchError(this.handleError<any>('get')));
    }
}
