import {
    Pipe,
    PipeTransform,
    OnDestroy,
    WrappedValue,
    ChangeDetectorRef
} from '@angular/core';

import { Subscription ,  Observable ,  BehaviorSubject } from 'rxjs';


import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { UrlService } from '@app/shared/services';

// Using similarity from AsyncPipe to avoid having to pipe |secure|async in HTML.
@Pipe({
    name: 'secure',
    pure: false
})
export class SecurePipe implements PipeTransform, OnDestroy {
    private _latestValue: any = null;
    private _latestReturnedValue: any = null;
    private _subscription: Subscription = null;
    private _obj: Observable<any> = null;

    private previousUrl: string;
    private _result: BehaviorSubject<any> = new BehaviorSubject(null);
    private result: Observable<any> = this._result.asObservable();
    private _internalSubscription: Subscription = null;

    constructor(
        private _ref: ChangeDetectorRef,
        private urlService: UrlService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnDestroy(): void {
        if (this._subscription) {
            this._dispose();
        }
    }

    transform(url: string): any {
        if (this.isDataURL(url)) {
            return url;
        }
        const obj = this.internalTransform(url);
        return this.asyncTrasnform(obj);
    }

    // https://gist.github.com/bgrins/6194623
    private isDataURL(s) {
        // tslint:disable-next-line:max-line-length
        const regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

        return !!s.match(regex);
    }
    private internalTransform(url: string): Observable<any> {
        if (!url) {
            return this.result;
        }

        if (this.previousUrl !== url) {
            this.previousUrl = url;
            this._internalSubscription = this.urlService
                .get(url)
                .subscribe(m => {
                    const sanitized = this.sanitizer.bypassSecurityTrustUrl(m);
                    this._result.next(sanitized);
                });
        }

        return this.result;
    }

    private asyncTrasnform(obj: Observable<any>): any {
        if (!this._obj) {
            if (obj) {
                this._subscribe(obj);
            }
            this._latestReturnedValue = this._latestValue;
            return this._latestValue;
        }
        if (obj !== this._obj) {
            this._dispose();
            return this.asyncTrasnform(obj);
        }
        if (this._latestValue === this._latestReturnedValue) {
            return this._latestReturnedValue;
        }
        this._latestReturnedValue = this._latestValue;
        return WrappedValue.wrap(this._latestValue);
    }

    private _subscribe(obj: Observable<any>) {
        const _this = this;
        this._obj = obj;

        this._subscription = obj.subscribe({
            next: function(value) {
                return _this._updateLatestValue(obj, value);
            },
            error: (e: any) => {
                throw e;
            }
        });
    }

    private _dispose() {
        this._subscription.unsubscribe();
        this._internalSubscription.unsubscribe();
        this._internalSubscription = null;
        this._latestValue = null;
        this._latestReturnedValue = null;
        this._subscription = null;
        this._obj = null;
    }

    private _updateLatestValue(async: any, value: Object) {
        if (async === this._obj) {
            this._latestValue = value;
            this._ref.markForCheck();
        }
    }
}
