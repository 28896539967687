import { Customer } from './../../../../shared/models/customer';
import { CustomerService } from './../../../../shared/services/customer/customer.service';
import { Component, OnInit } from '@angular/core';
import { ReportStockService } from '@app/shared/services/reports/report-stock/report-stock.service';

@Component({
  selector: 'app-stock-report',
  templateUrl: './stock-report.component.html',
  styleUrls: ['./stock-report.component.scss']
})
export class StockReportComponent implements OnInit {
    customers: Customer[];
    data: any = {};

  constructor(private _reportStockService: ReportStockService, private _customerService: CustomerService) { }

  ngOnInit() {
      this._customerService.all().subscribe((customers) => {
            this.customers = customers;
        });
    }

  onExportExcel(params) {
        this._reportStockService.downloadExcel(params);
    }

}
