import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from '../models';
import { UserService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class UserNotCustomerGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | UrlTree {
        const userIsCustomer = this.userService.userType();

        if (userIsCustomer) {
            return this.router.createUrlTree([userIsCustomer]);
        } else {
            return of(true);

        }
    }

}
