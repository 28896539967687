import { Component, OnInit } from '@angular/core';
import { CustomerService } from '@app/shared';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { ReportIntegrationService } from '@app/shared/services/reports/report-integration/report-integration.service';

@Component({
    selector: 'app-integration-report',
    templateUrl: './integration-report.component.html',
    styleUrls: ['./integration-report.component.scss']
})
export class IntegrationReportComponent implements OnInit {
    reportIntegration$: any;
    customer$: any;

    constructor(private _reportIntegrationService: ReportIntegrationService, private _customerService: CustomerService,
        private alertService: AlertService) { }

    ngOnInit() {
        this._reportIntegrationService.getAll().subscribe((reportIntegration) => {
            this.reportIntegration$ = reportIntegration;
            console.log(reportIntegration);
        });
        this._customerService.all().subscribe((customers) => {
            this.customer$ = customers;
            console.log(customers);
        });
    }

    reCreateOrder(nNF) {
        this._reportIntegrationService.reCreateOrdersPacco(nNF).subscribe((_) => {
        });
    }

    onNewOrder(event) {
        this._reportIntegrationService.create(event).subscribe((response: any) => {
            if (response[0].erros) {
                if (response[0].erros.includes('Itens => Codigos nao encontrados no sistema')) {
                    this.alertService.error('Erro ao criar o pedido, produto invalido');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            }
        });
    }
}
