import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurePipe } from './secure.pipe';
import { UrlService } from '@app/shared/services';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [UrlService],
    declarations: [SecurePipe],
    exports: [SecurePipe]
})
export class PipesModule { }
