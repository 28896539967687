import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {
    trigger,
    style,
    transition,
    animate,
    query,
    stagger,
    animateChild
} from '@angular/animations';

@Component({
    selector: 'app-table-list',
    templateUrl: './table-list.component.html',
    styleUrls: ['./table-list.component.scss'],
    animations: [
        trigger('list', [
            transition(':enter', [
                // child animation selector + stagger
                query('@items', stagger(300, animateChild()), {
                    optional: true
                })
            ])
        ]),
        trigger('items', [
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),
                animate(
                    '1s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(1)', opacity: 1 })
                )
            ]),
            transition(':leave', [
                style({ opacity: 1, height: '*' }),
                animate(
                    '1s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(0.5)', opacity: 0, height: 0 })
                )
            ])
        ])
    ]
})
export class TableListComponent implements OnInit, OnChanges {
    @Input() headers: String[] = [];
    @Input() objects: Object[] = [];
    @Input() props: String[] = [];

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
        }
    }
    trackByFn(index, object) {
        return index;
    }
}
