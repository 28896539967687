const base = {
    local: {
        API_ENDPOINT: 'http://localhost:4955/api/v1',
        OAUTH_ENDPOINT: 'http://localhost:4955/oauth/token'
    },
    prod: {
        API_ENDPOINT: 'https://sistema.lmxlogistica.com.br/api/v1',
        OAUTH_ENDPOINT: 'https://sistema.lmxlogistica.com.br/oauth/token'
    },
    dev: {
        API_ENDPOINT: 'http://homologacao.lmxlogistica.com.br:4955/api/v1',
        OAUTH_ENDPOINT:
            'http://homologacao.lmxlogistica.com.br:4955/oauth/token'
    }
};

export const environment: Environment = {
    production: false,
    dev: false,
    local: true,

    API_ENDPOINT: base.prod.API_ENDPOINT,
    OAUTH_ENDPOINT: base.prod.OAUTH_ENDPOINT,

    CLIENT_ID: 'lmx-logistica',
    CLIENT_SECRET: 'lmx-logistica@password',
    GRANT_TYPE_PASSWORD: 'password',
    GRANT_TYPE_REFRESH: 'refresh_token',
    SCOPE: 'account'
};

export class Environment {
    production: boolean;
    dev: boolean;
    local: boolean;
    API_ENDPOINT: string;
    OAUTH_ENDPOINT: string;
    CLIENT_ID: string;
    CLIENT_SECRET: string;
    GRANT_TYPE_PASSWORD: string;
    GRANT_TYPE_REFRESH: string;
    SCOPE: string;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
