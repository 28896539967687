import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: AutocompleteComponent, multi: true }
  ],
})
export class AutocompleteComponent implements OnChanges, OnInit {

  @Input() public placeholder: string;
  @Input() public valuefield: string;
  @Input() public valuelabel: string;
  @Input() public additionalFields: string;
  @Input() public optionList: Array<any>;
  @Input() public selectedList: Array<any>;
  @Output() public Select = new EventEmitter<any>();
  @Output() public Remove = new EventEmitter<any>();

  visible = false;
  query: string;
  filteredList: Array<any> = [];
  options: Array<any>;
  selected: Array<any> = [];


  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.optionList) {
      this.filteredList = this.options = this.cloneOptions(this.optionList);
    }

    if (this.selectedList && this.selectedList.length && this.options && this.options.length) {
      this.selected = this.cloneOptions(this.selectedList);

      this.filteredList = this.options = this.options.filter((opt) => {
        let FLAG = true;
        for (const sel of this.selected) {
          if (sel.value === opt.value) {
            FLAG =  false;
          }
        }
        return FLAG;
      });
    }
  }

  private cloneOptions(options) {
    if (this.additionalFields) {
      let arrayProps;
      const moreFilds = this.additionalFields.indexOf(',') > -1;
      if (moreFilds) {
        const arrayfields = this.additionalFields.split(',');

        arrayProps = arrayfields.map((prop) => {
          prop = prop.replace(/\s+/g, '');
          if ( prop.match(/[.]/g)) {
            return prop.split('.');
          }
          return prop;
        });

        return options.map(option => {
          const obj = {
            value: option[this.valuefield],
            label: option[this.valuelabel],
          };

          for (const prop of arrayProps) {
            if (prop instanceof Array) {
              obj[prop[0]] = option[prop[0]][prop[1]];
            } else {
              obj[prop] = option[prop];
            }
          }

          return obj;

        });
      } else {
        let prop: any;
        if (this.additionalFields.match(/[.]/g)) {
             prop = this.additionalFields.split('.');
        } else {
            prop = this.additionalFields;
        }

        if (prop instanceof Array) {
          return options.map(option => ({
            value: option[this.valuefield],
            label: option[this.valuelabel],
            [prop[0]]: option[prop[0]][prop[1]]
          }));
        } else {
          return options.map(option => ({
            value: option[this.valuefield],
            label: option[this.valuelabel],
            [prop]: option[prop]
          }));
        }
      }
    } else {
      return options.map(option => ({
        value: option[this.valuefield],
        label: option[this.valuelabel]
      }));
    }

  }

  filter() {
    if (!this.query) {
      this.filteredList = this.options;
      return;
    }

    if (this.query) {
      this.filteredList = this.options.filter(function (el) {
        if (el.label) {
            return el.label.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
        }
        return false;
      }.bind(this));
    } else {
      this.filteredList = [];
    }

    return;
  }

  hideSuggestions() {
    setTimeout(() => {
      this.visible = false;
    }, 300);
  }

  showSuggestions() {
    if (this.filteredList.length === 0) {
      this.filteredList = this.options.filter((ele) => {
        return this.selected.indexOf(ele) === -1;
      });
    }
    this.visible = true;
  }

  select(item) {
    this.selected.push(item);
    this.query = '';
    this.filteredList = [];
    this.Select.emit(item);
  }
  remove(item) {
    if (this.options.indexOf(item) === -1) {
        this.options.push(item);
    this.selected.splice(this.selected.indexOf(item), 1);
    this.filteredList = this.options.filter((ele) => {
      return this.selected.indexOf(ele) === -1;
    });
    this.Remove.emit(item);
    }
  }
}
