import { UserService } from '../user/user.service';
import { User, Token, Account } from '../../models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BaseService } from '../base.service';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OauthService extends BaseService {
    data: any;

    constructor(
        public http: HttpClient,
        private userService: UserService,
        alertService: AlertService
    ) {
        super(alertService, http);
    }

    oauth(account: Account): Observable<Token> {
        const body = new URLSearchParams();
        body.set('username', account.email);
        body.set('password', account.password);
        body.set('client_id', this.environment.CLIENT_ID);
        body.set('client_secret', this.environment.CLIENT_SECRET);
        body.set('grant_type', this.environment.GRANT_TYPE_PASSWORD);
        body.set('scope', this.environment.SCOPE);

        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post<Token>(
            this.environment.OAUTH_ENDPOINT,
            body.toString(),
            {
                headers: new HttpHeaders().set(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                )
            }
        );
    }

    getUser(): Observable<User> {
        return this.http
            .get<User>(this.environment.API_ENDPOINT + '/users/authenticated')
            .pipe(catchError(this.handleError<User>('getUser')));
    }

    logout() {
        this.userService.clearUser();
    }

    refreshToken(): Observable<Token> {
        const token = this.userService.getUser().token;
        const body = new URLSearchParams();

        body.set('client_id', this.environment.CLIENT_ID);
        body.set('client_secret', this.environment.CLIENT_SECRET);
        body.set('grant_type', this.environment.GRANT_TYPE_REFRESH);
        body.set('refresh_token', token.refreshToken);

        const headers = new Headers();
        const h = new HttpHeaders();
        h.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http
            .post<Token>(this.environment.OAUTH_ENDPOINT, body.toString(), {
                headers: new HttpHeaders().set(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                )
            })
            .pipe(catchError(this.handleError<Token>('refreshToken')));
    }
}
