import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PanelCustomer } from '@app/shared';
import { BaseService } from '../base.service';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PanelService extends BaseService {



  constructor(public http: HttpClient, alertService: AlertService) {
    super(alertService, http);
    // this.constants = environment.get(EnvType.SP);
  }

  getAvailableClients() {
    return this.http.get(this.api + '/panels/available/customers');
  }

  getPanels() {
    return this.http.get(this.api + '/panels/customer');
  }

  getPanel(id): Observable<PanelCustomer> {
    return this.http.get<PanelCustomer>(this.api + `/panels/${id}/customer`)
      .pipe(catchError(this.handleError<PanelCustomer>(`getPanel: ${id}`)));

  }

  setPanel(data) {
    return this.http.post(this.api + '/panels/customer', data);
  }

  updatePanel(data) {
    return this.http.put(`${this.api}/panels/${data.id}/customer`, data);
  }

  uploadImage(file: any) {
    const input = new FormData();
    input.append('file', file);
    return this.http.post(this.api + '/file/panels/images', input);
  }

  // getClientPanel(clientID) {
  //   return this.http.get(this.api + `/panels/${clientID}/client`);
  // }

  getInternalPanel() {
    return this.http.get(this.api + '/panels/internal/data');
  }

  getPanelData(id) {
    return this.http.get(this.api + `/panels/${id}/customer/data`);
  }
  getPanelOperational() {
    return this.http.get(this.api + `/panels/operational/data`);
  }

  // Remover Não deve estar aqui
  getReports(): Observable<Blob> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Response-Type', 'application/pdf');

    return this.http.get(`${this.api}/reports/${123}/entry`, {
      responseType: 'blob'
    }); // .map(res => new Blob([res], { type: 'application/pdf' }));
  }

  getInvoicesEntry() {
    return this.http.get(`${this.api}/invoices?type=entry`);
  }

  getInvoicesOut() {
    return this.http.get(`${this.api}/invoices?type=out`);
  }

  getInvoiceBreakdowns() {
    return this.http.get(`${this.api}/invoices/breakdowns?code=2`);
  }
  // Remover Não deve estar aqui

  getOrders(queryParams: any): Observable<any> {
    const defaultParams = {};

    queryParams = {
      ...defaultParams,
      ...queryParams
    };

    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any>(`${this.api}/panels/picking/wholesale`, {
        params
      })
      .pipe(catchError(this.handleError<any>('getorder')));
  }

  initLastFiveDaysSocket(): Observable<any> {
    return this.http
      .get<any>(`${this.api}/panels/personalization`)
      .pipe(catchError(this.handleError<any>('initLastFiveDaysSocket')));
  }

  initDeliveryTimeOrder(): Observable<any> {
    return this.http
      .get<any>(`${this.api}/panels/expedition`)
      .pipe(catchError(this.handleError<any>('initDeliveryTimeOrder')));
  }

  panelOrdersPickingConference(queryParams?: {
    [key: string]: any;
  }): Observable<any[]> {
    const defaultParams = {};

    queryParams = {
      ...defaultParams,
      ...queryParams
    };

    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any[]>(`${this.api}/panels/order`, {
        params
      })
      .pipe(catchError(this.handleError<any[]>('panelOrdersPickingConference')));
  }

  ordersGeneric(customerCode?) {
    return this.http.post(`${this.api}/panels/order/generic`, customerCode);
  }
}
