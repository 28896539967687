import { User } from '@app/shared/models';
import { UserService, OauthService } from '@app/shared/services';
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    user: User;
    // token: Token;

    constructor(
        private router: Router,
        private userService: UserService,
        private oauthService: OauthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        this.user = this.userService.getUser();

        if (!this.user) {
            this.router.navigate(['/login']);
            return of(false);
        }

        if (this.user.tokenIsExpired) {
            return this.oauthService.refreshToken().pipe(
                map(token => {
                    const user = this.userService.getUser();
                    user.token = token;
                    this.userService.setUser(user);
                    this.router.navigateByUrl(
                        state.url ? state.url : '/dashboard'
                    );
                    return true;
                }),
                catchError((error: any) => {
                    this.router.navigate(['/login'], {
                        queryParams: { returnUrl: state.url }
                    });
                    return of(false);
                })
            );
        }
        return of(true);
    }
}
