import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FireCustomer } from './../../models';

@Injectable({
  providedIn: 'root'
})
export class FireserviceService {

  private dbPath = '/customers';
  private userID = ' ';

  customerRef: AngularFirestoreCollection<FireCustomer> = null;

  constructor(private db: AngularFirestore) {
    this.userID = localStorage.getItem('customer_code');
// db.setLogLevel('debug');

    // this.customerRef = db.collection(this.dbPath).doc(this.userID).collection('orders');
    const customerCollection = db.collection('/customers').doc('11').collection('orders');
    // customerCollection.get().then((querySnapshot) => {
    // querySnapshot.forEach((collection) => {
    //     console.log('collection id:' + collection.id);
    //     console.log('collection :' + collection);
    //     });
    // });


    // customerCollection.get()
    // .pipe()
    // .subscribe(res => {
    //     console.log(res);
    // });
  }

  alert() {
        alert('chegou');
  }

  createCustomer(customer: FireCustomer): void {
    this.customerRef = this.db.collection(this.dbPath).doc(this.userID).collection('orders');
    this.customerRef.add({...customer});
  }

  updateCustomer(key: string, value: any): Promise<void> {
        this.customerRef = this.db.collection(this.dbPath).doc(this.userID).collection('orders');
        return this.customerRef.doc(key).update(value);
  }

  deleteCustomer(key: string): Promise<void> {
    this.customerRef = this.db.collection(this.dbPath).doc(this.userID).collection('orders');
        return this.customerRef.doc(key).delete();
  }

  getCustomerList(key?: string): AngularFirestoreCollection<FireCustomer> {
    this.customerRef = this.db.collection(this.dbPath).doc(this.userID).collection(key);
    return this.customerRef;
  }

  getList(key?: string, status?: string): AngularFirestoreCollection<FireCustomer> {
    this.customerRef = this.db.collection(this.dbPath)
    .doc(this.userID).collection(key,
        ref => ref.where('status', '==', status)
        .limit(2000));
    return this.customerRef;
  }

  getListPrev(
        key?: string,
        status?: string,
        skip?: any
    ): AngularFirestoreCollection<any> {

    this.customerRef = this.db.collection(this.dbPath)
        .doc(this.userID).collection(key,
            ref => ref.where('status', '==', status)
            .orderBy('destinatario')
            .startAfter(20)
            .limit(20)
        );

    return this.customerRef;
  }


  create(order: any, key: any): void {
    this.customerRef = this.db.collection(this.dbPath).doc(this.userID).collection(key);
    this.customerRef.add({...order});
  }


  deleteAll() {
    this.customerRef.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
