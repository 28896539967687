import { Router } from '@angular/router';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Customer } from '@app/shared';

@Component({
  selector: 'app-report-picking',
  templateUrl: './report-picking.component.html',
  styleUrls: ['./report-picking.component.scss']
})
export class ReportPickingComponent implements OnInit {
  @Output() exportExcel = new EventEmitter<any>();

  @Input() customers: Customer;
  data: any = {};
  displayMonths = 1;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  backRouter() {
    this.router.navigate(['/reports/overview']);
  }

  downloadXlsx() {
    const params = {
      customerCode: this.data.customer,
      dateFrom: this.toFormatDate(this.data.dateFrom),
      dateTo: this.toFormatDate(this.data.dateTo)
    };
    this.exportExcel.emit(params);
  }

  toFormatDate(date): String {
    const dateTime = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    const [year, month, day, hour, minutes, seconds] = [
      ...dateTime.split(/[- :]/)
    ];
    return `${year}-${month}-${day}`;
  }

}
