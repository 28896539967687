import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AlertService } from '@app/shared/services/alert/alert.service';

@Injectable({ providedIn: 'root' })
export class HolidayService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    all() {
        return this.http.get(`${this.api}/holidays`);
    }

    between(start, end) {
        return this.http.get(`${this.api}/holidays/${start}/${end}`);
    }

    delete(id) {
        return this.http.delete(`${this.api}/holidays/${id}`);
    }

    update(id, data) {
        return this.http.put(`${this.api}/holidays/${id}`, data);
    }

    getClientsActive() {
        return this.http.get(`${this.api}/holidays/active`);
    }

    getClient(id) {
        return this.http.get(`${this.api}/holidays/${id}`);
    }

    getClientsRemote() {
        return this.http.get(`${this.api}/erp/holidays`, {
            headers: new HttpHeaders().set(
                'Content-Type',
                'application/json; charset=utf-8'
            )
        });
    }

    save(data) {
        return this.http.post(`${this.api}/holidays`, data);
    }

    uploadImage(file: any) {
        const input = new FormData();
        input.append('file', file);

        return this.http.post(`${this.api}/file/holidays/images`, input);
    }

    updateClient(data) {
        return this.http.put(`${this.api}/holidays`, data);
    }
}
