import { Action, createAction, props } from '@ngrx/store';
import { Jadlog } from '@app/shared/models';

export const ADD_JADLOG_TAG = '[ jadlog ] Add tag';
export const REMOVE_JADLOG_TAG = '[ jadlog ] Remove tag';

export class AddJadlog implements Action {
    readonly type = ADD_JADLOG_TAG;
    constructor(public payload: Jadlog) {}
}

export class RemoveJadlog implements Action {
    readonly type = REMOVE_JADLOG_TAG;
    constructor(public payload: number) {}
}

export type Actions = AddJadlog | RemoveJadlog;
