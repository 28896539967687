import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import { AlertService } from '../alert/alert.service';
import { Observable } from 'rxjs';
import { Pagination } from '@app/shared/models/pagination';
import { catchError, map } from 'rxjs/operators';
import { IOrderTag } from '@app/shared/models/order-tags';

@Injectable({
    providedIn: 'root'
})
export class TagService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    createTagCollection(collection, invoice = null) {
        return this.http
            .post<any>(`${this.api}/tags/tag-collection/${invoice}`, collection)
            .pipe(catchError(this.handleError<any>('get')));
    }

    createTagCollectionWI(collection) {
        return this.http
            .post<any>(`${this.api}/tags/tag-collection/`, collection)
            .pipe(catchError(this.handleError<any>('get')));
    }

    getTagCollection() {
        return this.http
            .get<any>(`${this.api}/tags/tag-collection`)
            .pipe(catchError(this.handleError<any>('get')));
    }

    getInvoiceCollection(invoice) {
        return this.http
            .get<any>(`${this.api}/tags/tag-collection/${invoice}`)
            .pipe(catchError(this.handleError<any>('get')));
    }

    printCollectionTag(id: number) {
        return this.http
            .get<any>(`${this.api}/tags/tag-collection/${id}/print`, {
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('get'))
            );
    }

    printTagVolumes(codwsaida: string) {
        return this.http
            .post(
                `${this.api}/tags/volume/print`,
                {
                    codwsaida: codwsaida
                },
                {
                    observe: 'response',
                    responseType: 'blob' as 'json'
                }
            )
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('printTagVolumes'))
            );
    }

    printTagCorreios(
        codwsaida: number,
        queryParams?: {
            [key: string]: any;
        }
    ): Observable<any> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<any>(`${this.api}/correios/${codwsaida}/tagcorreios`, {
                params,
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('get'))
            );
    }

    printPackingList(queryParams) {
        return this.http
            .post<any>(`${this.api}/conference/print/vol`, queryParams, {
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('post'))
            );
    }
}
