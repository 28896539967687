import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GlobalLoadingService } from './global-loading.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalLoadingInterceptorService {

    activeRequests = 0;

    constructor(
        private loadingScreenService: GlobalLoadingService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.activeRequests === 0) {
            this.loadingScreenService.startLoading();
        }
        // Ignore-Loading-Indicator
        if (!request.headers.has('ignore-loading-indicator')
            || request.headers.get('ignore-loading-indicator') !== 'true') {
            this.activeRequests++;
        }

        return next.handle(request).pipe(
            finalize(() => {
                this.activeRequests--;
                if (this.activeRequests === 0) {
                    this.loadingScreenService.stopLoading();
                }
            })
        );
    }
}
