import { Component, OnInit } from '@angular/core';
import { Customer, CustomerService } from '@app/shared';
import { ReportPickingService } from '@app/shared/services/reports/report-picking/report-picking.service';

@Component({
  selector: 'app-picking-report',
  templateUrl: './picking-report.component.html',
  styleUrls: ['./picking-report.component.scss']
})
export class PickingReportComponent implements OnInit {
  data: any = {};
  customers: Customer[];
  constructor(private reportPickingService: ReportPickingService, private _customerService: CustomerService) { }

  ngOnInit() {
    this._customerService.all().subscribe((customers) => {
      this.customers = customers;
    });
  }

  onExportExcel(params) {
    this.reportPickingService.downloadReportExcel(params);
  }
}

