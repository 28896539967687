import { AbstractControl } from '@angular/forms';

export function getClassToinput(control: AbstractControl) {
    if (!control) { return; }
    if (!control.pristine && control.invalid) {
        return 'is-invalid';
    }
    if (!control.pristine && control.valid) {
        return 'is-valid';
    }
}

export function toChunk(chunkSize: number, array: any[]): any[] {
    return [].concat.apply([],
        array.map(function (elem, i) {
            return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
        })
    );
}

export function isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}
