import { ReportConfernece } from '@app/shared/models/reportConference';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagination } from '@app/shared/models/pagination';
import { Customer } from '@app/shared/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-report-conferences',
    templateUrl: './report-conferences.component.html',
    styleUrls: ['./report-conferences.component.scss']
})
export class ReportConferencesComponent implements OnInit {
    @Input() conference: any;
    @Input() pagination: Pagination<any>;
    @Input() customers: Customer[];
    @Input() query: any;
    @Input() perPage: number;

    @Output() pageChange = new EventEmitter<any>();


    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    selection = new SelectionModel<any>(true, []);

    _modalContent: any;
    dataSource: any;
    data: any = [];
    nNF: string;


    displayedColumns: string[] = ['orderNumber', 'nNF', 'create', 'user', 'errors', 'volumes'];

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
        this.start();
    }

    async start() {
        const handle = setInterval(() => {
            if (this.conference) {
                this.conference.map((report: ReportConfernece) => {
                    this.data.push({
                        orderNumber: report.orderNumber,
                        nNF: report.nNF,
                        create: report.create,
                        errors: report.errors,
                        user: report.user,
                        volumes: report.volumes
                    });

                });

                this.dataSource = new MatTableDataSource<any>(
                    this.data
                );
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                clearInterval(handle);
            }
        }, 200);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    toogleSearch() {
        document.querySelector('.barSearch').classList.toggle('opened');
        document.querySelector('.contentDetailsFilter').classList.toggle('enabled');
    }

    setVolume(nNF: string) {
        const conference = this.data.find((item) => {
            return item.nNF === nNF;
        });
        const data = [];
        conference.volumes.map((volume) => {
            if (volume) {
                data.push(volume);
            }
        });
        return data;
    }

    setErro(nNF: string) {
        const conference = this.data.find((item) => {
            return item.nNF === nNF;
        });
        if (conference.errors) {
            return conference.errors;
        }
    }

    disableButton() {
        if (this.conference.errors) {
            return false;
        } else {
            return true;
        }
    }

    onPageChange(page: number) {
        this.pageChange.emit({
            ...this.query,
            page: page,
            per_page: this.perPage
        });
        window.scroll(0, 0);
    }

    open(content, _conferenceReport?: any) {
        if (_conferenceReport) {
            this._modalContent = _conferenceReport;
        }
        this.modalService.open(content, { size: 'lg' });
        this.nNF = _conferenceReport.nNF;
    }

    dismiss() {
        this.modalService.dismissAll();
    }
}
