import { Jadlog } from '@app/shared/models';
import * as jadlogActions from '../actions/jadlogprint.actions';

const initialState: Jadlog = {
    streetWarehouse: 'demo',
    positionWarehouse: 'demo',
    priority: 'demo',
    route: 'demo',
    idR: 'demo',
    shipmentID: 'demo',
    vol: 'demo',
    Weight: 1,
    origin: {
        nome: 'demo',
        endereco: 'demo',
        numero: 'demo',
        complemento: 'demo',
        bairro: 'demo',
        cep: 'demo',
        cidade: 'demo',
        uf: 'demo'
    },
    dest: {
        nome: 'demo',
        endereco: 'demo',
        numero: 'demo',
        complemento: 'demo',
        bairro: 'demo',
        cep: 'demo',
        cidade: 'demo',
        uf: 'demo',
        telefone: 'demo',
        email: 'demo'
    },
    typeSort: 'demo'
};

export function reducer(
    state: Jadlog[] = [initialState],
    action: jadlogActions.Actions
) {
    switch (action.type) {
        case jadlogActions.ADD_JADLOG_TAG:
            return [...state, action.payload];

        case jadlogActions.REMOVE_JADLOG_TAG:
            state.splice(action.payload, 1);
            return state;

        default:
            return state;
    }
}
