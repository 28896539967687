import { Deserializable } from './deserializable.model';
import { Carrier } from './carrier';
import { Packaging } from './packaging';

export class Invoice  {

    id: string;
    number: number;
    client: string;
    cnpj: string;
    project: string;
    social_name: string;
    observation: string;
    carrier_type: any[];
    quantity_pallet_received: number;
    quantity_pallet_mounted: number;

    itens: Item[];
    photos: Photo[];

    constructor(parameters) {
        Object.assign(this, parameters);
        // tslint:disable-next-line: no-use-before-declare
        this.itens.map<Item>( item => new Item(item));
        // tslint:disable-next-line: no-use-before-declare
        this.photos.map<Photo>( photo => new Photo(photo));
    }

}

export class Item {

    _id: string;
    code_prod: number;
    name_prod: string;
    qtd: number;
    lack: number;
    leftover: number;
    boxes: number;

    constructor(parameters) {
        Object.assign(this, parameters);
    }
}

export class Photo {
    constructor(parameters) {
        Object.assign(this, parameters);
    }
}
