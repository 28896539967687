import { Token } from './token';
import { Company } from './company';
import { Account } from './account';
import { Settings } from './settings';
import { Customer } from './customer';

export class User {
    private __id?: string;
    private _code?: number;
    private _name: string;
    private _password: string;
    private _token: Token;
    private _phone: string;
    private _account: Account;
    private _birthDate: Date;
    private _companies: Company[]; /// Errado deve ser Subsidiary[]
    private _customers: Customer[];
    private _gender: string;
    private _settings: Settings;
    private createdAt: Date;
    private updatedAt: Date;

    constructor(user) {
        Object.assign(this, user);
        this._token = new Token(this._token);
    }

    set id(value: string) {
        this.__id = value;
    }

    get id() {
        return this.__id;
    }

    set _id(value: string) {
        this.__id = value;
    }

    get _id() {
        return this.__id;
    }
    set active(value: boolean) {
        this._account.active = value;
    }

    get active() {
        return this._account.active;
    }

    set name(name: string) {
        this._name = name;
    }
    get name() {
        return this._name;
    }

    set code(code: number) {
        this._code = code;
    }
    get code() {
        return this._code;
    }

    set email(email: string) {
        this._account.email = email;
    }
    get email() {
        return this.account.email;
    }
    set password(password: string) {
        this._password = password;
    }
    get password() {
        return this._password;
    }
    clearPassword() {
        this._password = null;
    }
    set token(token) {
        this._token = new Token(token);
    }
    get token() {
        return this._token;
    }

    get phone() {
        return this._phone;
    }
    set phone(phone: string) {
        this._phone = phone;
    }
    get account() {
        return this._account;
    }
    set account(account) {
        this._account = new Account(account);
    }
    get birthDate() {
        return this._birthDate;
    }
    set birthDate(birthDate) {
        this._birthDate = new Date(birthDate);
    }
    get companies() {
        return this._companies;
    }
    set companies(companies) {
        this._companies = companies.map<Company>(
            (company) => new Company(company)
        );
    }

    get gender() {
        return this._gender;
    }
    set gender(gender) {
        this._gender = gender;
    }

    get settings() {
        return this._settings;
    }

    set settings(parameters) {
        this._settings = new Settings(parameters);
    }

    get authorizationHeader() {
        return this._token.authorizationHeader;
    }

    get tokenIsExpired() {
        if (!this._token) {
            return true;
        }

        if (this._token) {
            return this._token.isExpired;
        }
    }

    get refreshToken() {
        if (this._token) {
            return this._token.refreshToken;
        }
    }
}
