import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { Settings } from '../../models';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseService {
    currentLang: string;

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
        this.currentLang = 'pt';
    }

    setLanguage(lang: string) {
        this.currentLang = lang;
    }
    getLanguage() {
        return this.currentLang;
    }

    setDefaultCompany(id, data): Observable<Settings> {
        return this.http.put<Settings>(`${this.api}/settings/${id}`, data)
        .pipe(catchError(this.handleError<Settings>(`setDefaultCompany: ${id}`)));
    }
}
