import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  Subscriber } from 'rxjs';
import { UserService } from '@app/shared/services/user/user.service';
import { Token } from '@app/shared/models';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable({ providedIn: 'root' })
export class UrlService {
    token: Token;
    constructor(
        private http: HttpClient /* Your favorite Http requester */,
        private userService: UserService
    ) {}

    get(url: string): Observable<string> {
        return new Observable((observer: Subscriber<string>) => {
            let objectUrl: string = null;

            this.http
                .get(url, {
                    headers: this.getHeaders(),
                    responseType: 'blob'
                })
                .subscribe(m => {
                    objectUrl = URL.createObjectURL(m);
                    observer.next(objectUrl);
                });

            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }

    getHeaders(): HttpHeaders {
        const headers = new HttpHeaders();

        this.token = this.userService.getUser().token;

        // let token = this.authService.getCurrentToken();
        // let token = { access_token: 'ABCDEF' }; // Get this from your auth service.
        if (this.token) {
            headers.set('Authorization', this.token.authorizationHeader);
        }

        return headers;
    }
}
