import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Subsidiary } from '@app/shared';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    allSubsidiary(companyId): Observable<Subsidiary[]> {
        return this.http
            .get<Subsidiary[]>(
                `${this.api}/companies/${companyId}/subsidiaries`
            )
            .pipe(catchError(this.handleError<Subsidiary[]>('all', [])));
    }
}
