import { Account } from './account';

export class WMSCustomer {
    codigo: number;
    nome: string;
    clihash: string;
    projetos: any;


    constructor(parameters) {
        Object.assign(this, parameters);
    }

}
