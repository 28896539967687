import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Invoice, Item } from '@app/shared/models';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })

export class InvoiceService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
        // this.constants = environment.get(EnvType.SP);
    }

    // public handleError(error: HttpErrorResponse) {
    //   if (error.error instanceof ErrorEvent) {
    //     // A client-side or network error occurred. Handle it accordingly.
    //     console.error('An error occurred:', error.error.message);
    //   } else {
    //     // The backend returned an unsuccessful response code.
    //     // The response body may contain clues as to what went wrong,
    //     console.error(
    //       `Backend returned code ${error.status}, ` +
    //       `body was: ${error.error}`);
    //   }
    //   // return an observable with a user-facing error message
    //   return throwError(
    //     'Something bad happened; please try again later.');
    // };

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    // public log(message: string) {
    //     this.alertService.error(
    //         `InvoiceService: ${message}`,
    //         this.alertService.ANIMATE_FROM_LEFT
    //     );
    // }

    all(queryParams?: { [key: string]: any }): Observable<Invoice[]> {
        const defaultParams = {
            type: 'entry'
        };

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<Invoice[]>(`${this.api}/invoices`, { params })
            .pipe(catchError(this.handleError<Invoice[]>('all', [])));
    }

    getAllWholesaleInvoice() {
        return this.http
            .get(`${this.api}/invoicetag/wholesale`)
            .pipe(catchError(this.handleError<any>('getAllWholesaleInvoice')));
    }




    getInvoice(invoiceNumber: number): Observable<Invoice> {
        return this.http
            .get<Invoice>(`${this.api}/invoices/${invoiceNumber}`)
            .pipe(
                catchError(
                    this.handleError<Invoice>(
                        `getInvoice number=${invoiceNumber}`
                    )
                )
            );
    }

    updateItem(invoiceNumber: number, item: Item): Observable<Item> {
        return this.http
            .put<Item>(
                `${this.api}/invoices/${invoiceNumber}/item/${item._id}`,
                item
            )
            .pipe(
                catchError(
                    this.handleError<Item>(
                        `updateItem number=${invoiceNumber} _id=${item._id}`
                    )
                )
            );
    }

    updateInvoice(invoice: Invoice): Observable<Invoice> {
        return this.http
            .put<Invoice>(`${this.api}/invoices/${invoice.number}`, invoice)
            .pipe(
                catchError(
                    this.handleError<Invoice>(
                        `updateInvoice number=${invoice.number}`
                    )
                )
            );
    }

    downloadPdf(invoiceNumber: number): Observable<Blob> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Response-Type', 'application/pdf');
        return this.http.get(
            `${this.api}/reports/invoice/${invoiceNumber}/entry`,
            {
                responseType: 'blob'
            }
        );
        // .map(res => new Blob([res], { type: 'application/pdf' }));
    }

    printInvoiceWholesale(id: any): Observable<Blob> {
        return this.http.get(`${this.api}/invoicetag/${id}/print/invoiceWholesale`, {
            observe: 'response',
            responseType: 'blob' as 'json'
        })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('get'))
            );
    }
}
