import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportOverviewComponent } from './components/report-overview/report-overview.component';
import * as fromContainers from './containers';

const routes: Routes = [
  { path: '', component: fromContainers.ConferenceReportComponent },
  { path: 'orders', component: fromContainers.OrdersReportsComponent },
  { path: 'table', component: fromContainers.TableReportComponent },
  { path: 'stock', component: fromContainers.StockReportComponent },
  { path: 'integration', component: fromContainers.IntegrationReportComponent },
  { path: 'picking', component: fromContainers.PickingReportComponent },
  { path: 'overview', component: ReportOverviewComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
