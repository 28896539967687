
import { tap } from 'rxjs/operators';
import { User } from '../../models/user';
// import { Headers, Http, RequestOptions } from '@angular/http';
import { UserService } from '../user/user.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
    HttpClient,
    HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, Environment } from 'src/environments/environment';

import { Token } from '../../models/token';

@Injectable({ providedIn: 'root' })
export class OauthInterceptor implements HttpInterceptor {
    environment: Environment = environment;

    constructor(private http: HttpClient, private userService: UserService) { }

    refreshToken() {
        const token = this.userService.getUser().token;
        const body = new URLSearchParams();

        body.set('client_id', this.environment.CLIENT_ID);
        body.set('client_secret', this.environment.CLIENT_SECRET);
        body.set('grant_type', this.environment.GRANT_TYPE_REFRESH);
        body.set('refresh_token', token.refreshToken);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(
            this.environment.OAUTH_ENDPOINT,
            body.toString(),
            {
                headers
            }
        );
        // .map((res: any) => res.json())
        // .subscribe((res) => {
        //     this.user.setToken(res);
        //     this.userService.setUser(this.user);
        //     this.userService.retryFailedRequests();
        // });
    }

    addAuthHeader(request) {
        const token = this.userService.getUser().token;
        if (token && !token.isExpired) {
            return request.clone({
                headers: request.headers.set(
                    'Authorization',
                    token.authorizationHeader
                )
            });
        } else {
            return request.clone();
        }
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // const user = this.userService.getUser();
        // let authReq = request.clone();

        // if (user) {

        //     // console.log('Authorization' + user.getAuthorizationHeader())
        //     authReq = request.clone({ headers: request.headers.set('Authorization', user.getAuthorizationHeader()) });
        // }

        let authReq = this.addAuthHeader(request);
        const token = this.userService.getUser().token;
        if (token && token.isExpired) {
            this.userService.collectFailedRequest(authReq);
            // tslint:disable-next-line:no-shadowed-variable
            this.refreshToken().subscribe(
                (_token: any) => {
                    const user = this.userService.getUser();
                    user.token = _token;
                    this.userService.setUser(user);
                    authReq = this.addAuthHeader(request);
                    return next.handle(authReq);
                },
                error => {
                    // this.userService.clearUser();
                }
            );
        } else {
            return next.handle(authReq).pipe(tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // do stuff with response if you want
                    }
                },
                (err: any) => {
                    if (
                        err instanceof HttpErrorResponse &&
                        err.status === 401
                    ) {
                        // tslint:disable-next-line:no-shadowed-variable
                        // const user = this.userService.getUser();
                        // if (user && user.tokenIsExpired()) {
                        //     this.userService.collectFailedRequest(authReq);
                        //     this.refreshToken().map(res => res.json()).subscribe((token) => {
                        //         this.user.setToken(token);
                        //         this.userService.setUser(this.user);
                        //         const reqc = this.addAuthHeader(request);
                        //         return next.handle(reqc);
                        //     }, (error) => {
                        //             // this.userService.clearUser();
                        //     });
                        // }
                    }
                }
            ));
        }
    }
}
