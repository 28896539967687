import {
    CommonModule,
    PathLocationStrategy,
    LocationStrategy,
    registerLocaleData
} from '@angular/common';
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import ptBr from '@angular/common/locales/pt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, PipesModule } from './shared';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from './shared/modules/alert/alert.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ConstantsProvider } from './app.constants';
import { AlertService } from './shared/services/alert/alert.service';
import { RefreshTokenInterceptor } from './shared/services/oauth/token-interceptor.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalLoadingModule } from './shared/modules/global-loading/global-loading.module';
import { GlobalLoadingInterceptorService } from './shared/modules/global-loading/global-loading.interceptor';
import { ChartsModule } from 'ng2-charts';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
// import { reducers, metaReducers, effects } from './store';
import { reducer } from './store/reducers/jadlog.reducer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReportsModule } from './layout/reports/reports.module';
// import { OrderPersonalizationComponent } from './order-personalization/order-personalization.component';

// Firebase modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { env.FireEnv } from '../environments/environment';
// import * as env from '../environments/environment';

import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LayoutDocumentationComponent } from './layout-documentation/components/layout-documentation.component';


registerLocaleData(ptBr);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}


export interface Fireenv {
    production: boolean;
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        measurementId: string;
        appId: string;
    };
}

export const FireEnv: Fireenv = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyDV4SVRTXIB41YZm9xGbKSrGgw8g6KP7pY',
        authDomain: 'lmx-app-331217.firebaseapp.com',
        projectId: 'lmx-app-331217',
        storageBucket: 'lmx-app-331217.appspot.com',
        messagingSenderId: '654400260651',
        appId: '1:654400260651:web:390a0f42bfa765b630f86e',
        measurementId: 'G-1J5J5WLKG0'
    }
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        AlertModule,
        PipesModule,
        HttpClientModule,
        GlobalLoadingModule,
        ChartsModule,
        // EffectsModule.forRoot(effects),
        StoreModule.forRoot({
            TagsStore: reducer
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: false,
            features: {
                pause: false,
                lock: true,
                persist: true
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ReportsModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(FireEnv.firebaseConfig),
        AngularFirestoreModule.enablePersistence(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.dev }),
    ],
    providers: [
        ConstantsProvider,
        AuthGuard,
        AlertService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalLoadingInterceptorService,
            multi: true
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
