import {
    Component,
    ElementRef,
    ChangeDetectorRef,
    OnInit,
    AfterViewInit,
    OnDestroy,
    Renderer2,
    AfterContentChecked,
    AfterViewChecked,
    DoCheck
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalLoadingService } from './global-loading.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-global-loading',
    templateUrl: './global-loading.component.html',
    styleUrls: ['./global-loading.component.scss']
})
export class GlobalLoadingComponent implements OnDestroy, AfterViewInit {
    loadingSubscription: Subscription;
    disableRoutes = ['dashboard', 'orderConference'];
    activeLocal: any;
    enableLoading: Boolean;

    constructor(
        private loadingScreenService: GlobalLoadingService,
        private _elmRef: ElementRef,
        private _render: Renderer2,
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        public router: Router
    ) {

    }

    containsClassBody() {
        const body = document.getElementsByTagName('body')[0];
        let activePage = null;
        this.disableRoutes.forEach(classBody => {
            activePage = body.classList.contains(classBody);
            if (activePage) {
                return activePage;
            }
        });
        return  activePage ? activePage : false ;
        // return activePage;
    }


    ngAfterViewInit(): void {
        this._render.setStyle(this._elmRef.nativeElement, 'display', 'none');
        this.loadingSubscription = this.loadingScreenService.loading$
        .pipe()
        .subscribe((status: boolean) => {
            if (!this.containsClassBody()) {
                    this._render.setStyle(
                        this._elmRef.nativeElement,
                        'display',
                        status ? 'block' : 'none'
                    );
                }
                this._changeDetectorRef.detectChanges();
            });
    }

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
    }
}
