import { Account } from './account';
import { Customer } from './customer';

export class PanelCustomer  {

    id?: string;
    _id?: string;
    name: string;
    logo: string;
    active: boolean;
    account: Account;
    customers: Customer[];
    createdAt?: Date;
    updatedAt?: Date;


    constructor(parameters) {
        Object.assign(this, parameters);
        this.customers.map<Customer>( customer => new Customer(customer));
        this.account = new Account(this.account);
    }

}
