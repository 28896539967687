import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ReportIntegration } from '@app/shared/models/integration-report';
import { Observable } from 'rxjs';
import { type } from 'os';
import { LockChanges } from '@ngrx/store-devtools/src/actions';

@Component({
    selector: 'app-report-integration',
    templateUrl: './report-integration.component.html',
    styleUrls: ['./report-integration.component.scss']
})
export class ReportIntegrationComponent implements OnInit {

    @Input() integration: any;
    @Input() customers: any;
    @Output() newOrder = new EventEmitter();
    @Output() newOrderPacco = new EventEmitter();

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;


    types = [
        { number: 0, type: 'Crição de Pedidos' },
        { number: 1, type: 'Postagem de xml de venda de ecommerce' },
        { number: 2, type: 'Postagem de xml de venda atacado' },
        { number: 3, type: 'Postagem de nf de entrada' },
        { number: 4, type: 'Integração phx' },
        { number: 5, type: 'Integração bling' }
    ];

    details: any;

    // nfWithoutOrder: any = [];
    // nfWithOrder: any[] = [];
    // nNF: string;

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
    }

    getCustomer(code: any) {
        const res = this.customers.find(
            (customer) => customer.code === code
        );
        return res.name;
    }

    openModal(content, details?: any) {
        this.details = {
            error: details.error,
            type: details.type,
            date: details.date
        };
        this.modalService.open(content, { size: 'lg' });
    }

    getType(number) {
        const res = this.types.find(
            (item) => item.number === number
        );
        return res.type;
    }

    dismiss() {
        this.modalService.dismissAll();
    }
}

