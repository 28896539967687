import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Carrier } from '@app/shared/models';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class CarrierService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    all(queryParams?: { [key: string]: any }): Observable<Carrier[]> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<Carrier[]>(`${this.api}/carrier`, { params })
            .pipe(catchError(this.handleError<Carrier[]>('all', [])));
    }

    getCarrier(carrierNumber: number): Observable<Carrier> {
        return this.http
            .get<Carrier>(`${this.api}/carrier/${carrierNumber}`)
            .pipe(
                catchError(
                    this.handleError<Carrier>(
                        `getCarrier number=${carrierNumber}`
                    )
                )
            );
    }
}
