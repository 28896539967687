import { TableReportComponent } from './table-report/table-report.component';
import { ConferenceReportComponent } from './conference-report/conference-report.component';

import { FtpIntegrationsComponent } from './ftp-integrations/ftp-integrations.component';
import { OrdersReportsComponent } from './orders-reports/orders-reports.component';

import { StockReportComponent } from './stock-report/stock-report.component';
import { IntegrationReportComponent } from './integration-report/integration-report.component';
import { PickingReportComponent } from './picking-report/picking-report.component';



export const containers = [
    FtpIntegrationsComponent,
    OrdersReportsComponent,
    ConferenceReportComponent,
    TableReportComponent,
    StockReportComponent,
    IntegrationReportComponent,
    PickingReportComponent
];

export * from './ftp-integrations/ftp-integrations.component';
export * from './orders-reports/orders-reports.component';
export * from './conference-report/conference-report.component';
export * from './table-report/table-report.component';
export * from './stock-report/stock-report.component';
export * from './integration-report/integration-report.component';
export * from './picking-report/picking-report.component';
