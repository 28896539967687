import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalLoadingComponent } from './global-loading.component';
/* import { GlobalLoadingInterceptorService } from './global-loading.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'; */
@NgModule({
    declarations: [GlobalLoadingComponent],
    imports: [
        CommonModule,
        /* HttpClientModule */
    ],
    /*providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalLoadingInterceptorService,
            multi: true
        }
    ],*/
    exports: [GlobalLoadingComponent]
})
export class GlobalLoadingModule { }
