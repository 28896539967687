import { Directive, Renderer2, ElementRef, HostListener } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[dateInputMask]'
})
export class DateInputMaskDirective {

    constructor(private element: ElementRef,
        private render: Renderer2) {}

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        const input = event as String;
        if (input === null || input.length === 0 || input instanceof Date) { return; }
        let masked = '';
        const text = input.replace(/[^0-9]+/gi, '');
        if (text.length >= 2 && text.length <= 3) {
            masked = text.replace(/^(\d{2})(\d{0,1})/, '$1/$2');
        } else if (text.length === 4) {
            masked = text.replace(/^(\d{2})(\d{2})/, '$1/$2/');
        } else if (text.length >= 5 && text.length <= 8) {
            masked = text.replace(/^(\d{2})(\d{2})(\d{1,4})/, '$1/$2/$3');
        } else {
            masked = text;
        }
        this.returnValue(masked);
    }

    returnValue(value) {
        this.render.setProperty(this.element.nativeElement, 'value', value );
    }

}
