import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Packaging } from '@app/shared/models';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class PackagingService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    all(queryParams?: { [key: string]: any }): Observable<Packaging[]> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<Packaging[]>(`${this.api}/packaging`, { params })
            .pipe(catchError(this.handleError<Packaging[]>('all', [])));
    }

    getPackaging(packingNumber: number): Observable<Packaging> {
        return this.http
            .get<Packaging>(`${this.api}/packaging/${packingNumber}`)
            .pipe(
                catchError(
                    this.handleError<Packaging>(
                        `getPacking number=${packingNumber}`
                    )
                )
            );
    }
}
