export class Role {
    id?: string;
    _id?: string;
    _name: string;
    _inherits: Role[] | string[];
    _permission: {
        _can: string[];
        menus: any[];
    };



    constructor(parameters) {
        Object.assign(this, parameters);
    }

    set name(parameter: string) {
        this._name = parameter;
    }
    get name() {
        return this._name;
    }
    set inherits(parameter: string[] | Role[]) {
        this._inherits = parameter;
    }
    get inherits() {
        return this._inherits;
    }
}
