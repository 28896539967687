import { Component, OnInit } from '@angular/core';
import { Customer, CustomerService } from '@app/shared';
import { ReportOrdersService } from '@app/shared/services/reports/report-orders/report-orders.service';

@Component({
    selector: 'app-orders-reports',
    templateUrl: './orders-reports.component.html',
    styleUrls: ['./orders-reports.component.scss']
})
export class OrdersReportsComponent implements OnInit {
    customers: Customer[];
    data: any = {};
    constructor(private _reportOrderService: ReportOrdersService, private _customerService: CustomerService) { }

    ngOnInit() {
        this._customerService.all().subscribe((customers) => {
            this.customers = customers;
        });
    }

    onExportExcel(params) {
        this._reportOrderService.downloadExcel(params);
    }
}
