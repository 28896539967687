import { OrderService } from '@app/shared/services/order/order.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-table-report',
    templateUrl: './table-report.component.html',
    styleUrls: ['./table-report.component.scss']
})
export class TableReportComponent implements OnInit {
    table$: any;

    constructor(private _orderService: OrderService) {}

    ngOnInit() {
        this._orderService.getIndex().subscribe((pagination) => {
            this.table$ = pagination;
        });
    }
}
