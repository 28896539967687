import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { OauthService } from './oauth.service';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { take, switchMap, catchError, map, filter } from 'rxjs/operators';
import { UserService } from '@app/shared';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject rastreia o token atual ou é nulo se nenhum token for atualmente
    // disponível (por exemplo, atualização pendente).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<
        any
    >(null);
    constructor(
        public oauthService: OauthService,
        private userService: UserService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthHeader(request)).pipe(
            catchError(error => {
                // Não queremos atualizar o token para alguns pedidos, como o login ou o próprio token de atualização
                // Assim, verificamos o URL e lançamos um erro, se for o caso
                if (
                    request.url.includes('/oauth/token') ||
                    request.url.includes('login')
                ) {
                    // Fazemos outra verificação para ver se o token de atualização falhou
                    // Neste caso, queremos sair do usuário e redirecioná-lo para a página de login

                    if (request.url.includes('/oauth/token')) {
                        this.oauthService.logout();
                    }

                    return throwError(error);
                } // Então nós checamos isso e lançamos o erro se for o caso
                // Se o status do erro for diferente de 401, queremos pular o token de atualização
                if (error.status !== 401) {
                    return throwError(error);
                }

                if (this.refreshTokenInProgress) {
                    // Se refreshTokenInProgress for true, aguardaremos até que refreshTokenSubject tenha um valor não nulo
                    // - o que significa que o novo token está pronto e podemos tentar novamente a solicitação
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() =>
                            next.handle(this.addAuthHeader(request))
                        )
                    );
                } else {
                    this.refreshTokenInProgress = true;

                    // Defina o refreshTokenSubject como null para que as chamadas de
                    // API subsequentes esperem até que o novo token seja recuperado
                    this.refreshTokenSubject.next(null);

                    // Chame auth.refreshAccessToken (este é um Observável que será retornado)
                    return this.oauthService.refreshToken().pipe(
                        switchMap(token => {
                            // Quando a chamada para atualizar o Token for concluída,
                            // redefiniremos o Token InProgress de atualização para false
                            // para a próxima vez que o token precisar ser atualizado

                            this.refreshTokenInProgress = false;
                            this.refreshTokenSubject.next(token);

                            const user = this.userService.getUser();
                            user.token = token;
                            this.userService.setUser(user);

                            return next.handle(this.addAuthHeader(request));
                        }),
                        catchError((err: any) => {
                            this.refreshTokenInProgress = false;

                            this.oauthService.logout();
                            return throwError(error);
                        })
                    );
                }
            })
        );
    }

    addAuthHeader(request) {
        const user = this.userService.getUser();
        if (user && user.token) {
            const token = user.token;
            return request.clone({
                headers: request.headers.set(
                    'Authorization',
                    token.authorizationHeader
                )
            });
        } else {
            return request;
        }
    }
}
