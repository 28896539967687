import { Deserializable } from './deserializable.model';

export class Attachment  {
    id?: any;
    name: string;
    type: string;

    constructor(parameters) {
        Object.assign(this, parameters);
    }

}
