import { Router } from '@angular/router';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Customer } from '@app/shared';

@Component({
    selector: 'app-report-orders',
    templateUrl: './report-orders.component.html',
    styleUrls: ['./report-orders.component.scss']
})
export class ReportOrdersComponent implements OnInit {
    @Input() customers: Customer;
    @Output() exportExcel = new EventEmitter<any>();

    data: any = {};
    displayMonths = 1;

    constructor(private router: Router) {}

    ngOnInit() {}

    downloadXlsx() {
        const params = {
            customerCode: this.data.customer,
            dateFrom: this.toFormatDate(this.data.dateFrom),
            dateTo: this.toFormatDate(this.data.dateTo)
        };
        this.exportExcel.emit(params);
    }

      backRouter() {
        this.router.navigate(['/reports/overview']);
    }

    toFormatDate(date): String {
        const dateTime = new Date(date)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ');
        const [year, month, day, hour, minutes, seconds] = [
            ...dateTime.split(/[- :]/)
        ];
        return `${year}-${month}-${day}`;
    }
}
