import { AlertComponent } from './alert.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService } from '@app/shared/services/alert/alert.service';
// TODO: Fazer isso

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        AlertComponent
    ],
    exports: [AlertComponent],
    providers: [AlertService]
})
export class AlertModule { }
