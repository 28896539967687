import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, UserNotCustomerGuard, UserNotBeepManagerGuard } from './shared';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [AuthGuard, UserNotCustomerGuard]
    },

    {
        path: 'customer',
        loadChildren: () => import('./layout-customer/layout-customer.module').then(m => m.LayoutCustomerModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'personalization',
        loadChildren: () => import('./order-personalization/personalization/personalization.module').then(m => m.PersonalizationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'panel',
        loadChildren: () => import('./layout-panels/layout-panels.module').then(m => m.LayoutPanelsModule)
    },
    {
        path: 'documentation',
        loadChildren: () => import('./layout-documentation/layout-documentation.module').then(m => m.LayoutDocumentationModule)
    },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found' },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
