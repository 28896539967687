import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert/alert.service';
import { Observable } from 'rxjs';
import { Pagination } from '@app/shared/models/pagination';
import { catchError, map } from 'rxjs/operators';
import { OrderConference } from '@app/shared/models/order-conference';

@Injectable({
    providedIn: 'root'
})
export class OrderService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    postVols(order) {
        return this.http
            .post(`${this.api}/conference/vol`, order)
            .pipe(catchError(this.handleError<any>('postVols')));
    }

    getIndex(queryParams?: {
        [key: string]: any;
    }): Observable<Pagination<any>> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<Pagination<any>>(`${this.api}/orders/`, { params })
            .pipe(catchError(this.handleError<Pagination<any>>('getIndex')));
    }

    getAll(queryParams?: { [key: string]: any }): Observable<Pagination<any>> {
        const defaultParams = {};
        queryParams = {
            ...defaultParams,
            ...queryParams
        };
        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<Pagination<any>>(`${this.api}/orders/`, { params })
            .pipe(catchError(this.handleError<Pagination<any>>('getIndex')));
    }

    getOrderStatus(queryParams?: { [key: string]: any }): Observable<any[]> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<any[]>(`${this.api}/orders/status`, { params })
            .pipe(catchError(this.handleError<any[]>('getIndex')));
    }

    get(
        id: number,
        queryParams?: {
            [key: string]: any;
        }
    ): Observable<any> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<any>(`${this.api}/orders/${id}`, { params })
            .pipe(catchError(this.handleError<any>('get')));
    }

    getByWsaida(wSaida: any): Observable<any> {
        return this.http
            .get<any>(`${this.api}/orders/conference/${wSaida}`)
            .pipe(catchError(this.handleError<any>('get')));
    }

    getOrderByIdCliPed(idCliPed: any): Observable<any> {
        return this.http
            .get<any>(`${this.api}/orders/${idCliPed}/customer`)
            .pipe(catchError(this.handleError<any>('get')));
    }

    saveHistory(id: number, history) {
        return this.http
            .post<any>(`${this.api}/orders/${id}/history`, history)
            .pipe(catchError(this.handleError<any>('saveHistory')));
    }

    deleteHistory(id: number, historyId) {
        return this.http
            .delete<any>(`${this.api}/orders/${id}/history/${historyId}`)
            .pipe(catchError(this.handleError<any>('deleteHistory')));
    }

    saveError(error) {
        return this.http
            .post<any>(`${this.api}/orders/error`, error)
            .pipe(catchError(this.handleError<any>('saveError')));
    }

    saveXML(id: number, XML: File) {
        const formData = new FormData();
        formData.append('file', XML);
        return this.http
            .post<any>(`${this.api}/orders/${id}/xml`, formData)
            .pipe(catchError(this.handleError<any>('saveXML')));
    }

    saveCTE(id: number, CTE: File) {
        const formData = new FormData();
        formData.append('file', CTE);
        return this.http
            .post<any>(`${this.api}/orders/${id}/xmlcte`, formData)
            .pipe(catchError(this.handleError<any>('saveCTE')));
    }

    printVolumesTag(id: number) {
        return this.http
            .get<any>(`${this.api}/orders/${id}/print/tag`, {
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('get'))
            );
    }

    newConference(conference: any): Observable<any> {
        console.log(conference);
        return this.http
            .post<any>(`${this.api}/conference`, conference)
            .pipe(catchError(this.handleError<any>('sendItemsforConference')));
    }

    getConference(
        wSaida: number,
        queryParams?: {
            [key: string]: any;
        }
    ): Observable<any> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http.get<any>(`${this.api}/conference/${wSaida}`, {
            params
        });
    }

    sendItemsforConference(
        id: number,
        conference: OrderConference
    ): Observable<OrderConference> {
        return this.http
            .post<OrderConference>(`${this.api}/conference/${id}`, conference)
            .pipe(
                catchError(
                    this.handleError<OrderConference>('sendItemsforConference')
                )
            );
    }

    printVolumTagConference(
        id: number,
        queryParams?: {
            [key: string]: any;
        }
    ): Observable<any> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        console.log(params, 'params new ');
        return this.http
            .get<any>(`${this.api}/conference/${id}/print/vol`, {
                params,
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('get'))
            );
    }

    printTagConference(queryParams) {
        return this.http
            .post<any>(`${this.api}/conference/print/conference`, queryParams, {
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.printPdf(response);
                    return response.body;
                }),
                catchError(this.handleError<any>('post'))
            );
    }

    export(queryParams?: { [key: string]: any }): Observable<Blob> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<Blob>(`${this.api}/orders/export`, {
                params,
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.saveFile(response);
                    return response.body;
                }),
                catchError(this.handleError<Blob>('export'))
            );
    }

    createError(error: any): Observable<any> {
        return this.http
            .post<any>(`${this.api}/orders/error`, error)
            .pipe(catchError(this.handleError<any>('createError')));
    }
    updateError(update: any): Observable<any> {
        return this.http
            .post<any>(`${this.api}/orders/error/personalization`, update)
            .pipe(catchError(this.handleError<any>('updateError')));
    }

    cancelOrder(id: any): Observable<any> {
        return this.http
            .post<any>(`${this.api}/orders/cancel`, id)
            .pipe(catchError(this.handleError<any>('cancelOrder')));
    }

    getOrderByWsaida(wSaida: any): Observable<any> {
        return this.http
            .get<any>(`${this.api}/conference/order/${wSaida}`)
            .pipe(catchError(this.handleError<any>('get')));
    }

    getAllOrderInEachStatus(): Observable<any> {
        return this.http
            .get<any>(`${this.api}/orders/status/each`)
            .pipe(catchError(this.handleError<any>('get')));
    }

    compareInvoiceXMLWithDistinctInvoicesBSON(data) {
        const formData = new FormData();
        data.xml.map((file) => {
            formData.append('xml', file, 'xmlFile.xml');
        });
        formData.append('orders', data.orders);
        return this.http
            .post<any>(`${this.api}/orders/multipleOrderXML`, formData)
            .pipe(catchError(this.handleError<any>('entryInvoice')));
    }
}
