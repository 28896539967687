import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '../../alert/alert.service';
import { BaseService } from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportOrdersService extends BaseService {

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    downloadExcel( params: any) {
        this.downloadXLS(`${this.api}/reports/orders/excel`, params, 'orders');
    }
}
