import { Injectable } from "@angular/core";
@Injectable()
export class ConstantsProvider {

    // Local Docker --------------------------------------
    // API_ENDPOINT = 'http://192.168.99.100:4955/api';
    // OAUTH_ENDPOINT = 'http://192.168.99.100:4955/oauth/token';
    // Local Docker -------------------------------------

    // Local --------------------------------------
    // API_ENDPOINT = 'http://localhost:4955/api';
    // OAUTH_ENDPOINT = 'http://localhost:4955/oauth/token';
    // Local --------------------------------------

    // //Homologacao --------------------------------------
    // API_ENDPOINT = 'http://homologacao.lmxlogistica.com.br:4955/api';
    // OAUTH_ENDPOINT = 'http://homologacao.lmxlogistica.com.br:4955/oauth/token';
    // //Homologacao --------------------------------------

    // //Produção --------------------------------------
    API_ENDPOINT = 'http://sistema.lmxlogistica.com.br:4955/api';
    OAUTH_ENDPOINT = 'http://sistema.lmxlogistica.com.br:4955/oauth/token';
    // //Homologacao --------------------------------------

    CLIENT_ID = 'lmx-logistica';
    CLIENT_SECRET = 'lmx-logistica@password';
    GRANT_TYPE_PASSWORD = 'password';
    GRANT_TYPE_REFRESH = 'refresh_token';
}
