import { NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { isInteger } from '@app/shared/helpers/functions.helper';

@Injectable()
export class DateNativeAdapter extends NgbDateAdapter<Date> {
    /**
   * Converts a native `Date` to a `NgbDateStruct`.
   */
    fromModel(date: Date): NgbDateStruct {
        return (date instanceof Date && !isNaN(date.getTime())) ? this._fromNativeDate(date) : null;
    }

    /**
     * Converts a `NgbDateStruct` to a native `Date`.
     */
    toModel(date: NgbDateStruct): Date {
        return date && isInteger(date.year) && isInteger(date.month) && isInteger(date.day) ? this._toNativeDate(date) :
            null;
    }

    protected _fromNativeDate(date: Date): NgbDateStruct {
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    protected _toNativeDate(date: NgbDateStruct): Date {
        const now = new Date();
        const jsDate = new Date(date.year, date.month - 1, date.day);
        jsDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
        // avoid 30 -> 1930 conversion
        jsDate.setFullYear(date.year);

        return jsDate;
    }
}
