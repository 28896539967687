export class Company {
    private __id?: string;
    name: string;

    subsidiaries: (string | Subsidiary)[];

    constructor(parameters) {
        Object.assign(this, parameters);
        // tslint:disable-next-line: no-use-before-declare
        if (this.subsidiaries) { this.subsidiaries.map(e => new Subsidiary(e)); }
    }

    set id(value: string) {
        this.__id = value;
    }
    get id() {
        return this.__id;
    }
    set _id(value: string) {
        this.__id = value;
    }
    get _id() {
        return this.__id;
    }
}

export class Subsidiary {
    private __id?: string;
    name: string;
    company: Company | string;

    constructor(parameters) {
        Object.assign(this, parameters);

        if (typeof parameters === 'string') {
            this.company = parameters;
        }
        if (typeof this.company !== 'string') {
            this.company = new Company(this.company);
        }
    }

    set id(value: string) {
        this.__id = value;
    }
    get id() {
        return this.__id;
    }
    set _id(value: string) {
        this.__id = value;
    }
    get _id() {
        return this.__id;
    }
}
