import { Injectable } from '@angular/core';
import { User } from '../../models';
import { HttpRequest, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { Role } from '../../models/role';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
    private user: User;
    cachedRequests: Array<HttpRequest<any>> = [];

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    getUser(): User {
        if (!this.user) {
            const user = JSON.parse(localStorage.getItem('user'));
            // para remover os _ do dado persistido, e utilizar os get e set corretamente
            for (const key in user) {
                if (user.hasOwnProperty(key)) {
                    user[key.replace(/_/g, '')] = user[key];
                    if (key !== 'code') {
                        delete user[key];
                    }
                }
            }
            if (user && user.token) {
                this.user = new User(user);
            }
        }
        return this.user;
    }

    setUser(user: User) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    }

    clearUser() {
        this.user = null;
        localStorage.removeItem('user');
    }

    collectFailedRequest(request): void {
        this.cachedRequests.push(request);
    }

    userIsCustomer(): boolean {
        const user = this.getUser();
        const role = new Role(user.account._role);
        if (role._name.toLowerCase().trim() !== 'customer') {
            return false;
        }
        return true;
    }

    userType(): any {
        const user = this.getUser();
        const role = new Role(user.account._role);

        switch (role._name.toLowerCase().trim()) {
            case 'customer':
                return '/customer/';
            case 'beepcustomer':
                return '/personalization/';
            case 'painel':
                return '/panel/personalization';
            case 'manager':
                return false;
            case 'user':
                return false;
        }
    }

    retryFailedRequests(): void {
        // retry the requests. this method can
        // be called after the token is refreshed
        // this.cachedRequests.forEach((request: HttpRequest<any>) => {
        //     this.http.request(request).subscribe((response) => {
        // // You need to subscribe to observer in order to "retry" your request
        //     });
        // });
        // this.http.request(request)
        //     .subscribe(
        //         event => {});
    }

    findAll(): Observable<User[]> {
        return this.http
            .get<User[]>(`${this.api}/users`)
            .pipe(catchError(this.handleError<User[]>(`findAll`)), take(1));
    }

    save(user): Observable<User> {
        return this.http
            .post<User>(`${this.api}/users`, user)
            .pipe(catchError(this.handleError<User>(`save`)), take(1));
    }

    update(user): Observable<User> {
        return this.http
            .put<User>(`${this.api}/users/${user.id}`, user)
            .pipe(catchError(this.handleError<User>(`save`)), take(1));
    }

    loadByID(id): Observable<User> {
        return this.http
            .get<User>(`${this.api}/users/${id}`)
            .pipe(catchError(this.handleError<User>(`loadByID`)), take(1));
    }
}
