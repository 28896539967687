import { Subsidiary } from './company';

export class Settings {
    _id?: string;
    id?: any;
    defaultCompany: Subsidiary;

    constructor(parameters) {
        Object.assign(this, parameters);
        this.defaultCompany = new Subsidiary(this.defaultCompany);
    }
}
