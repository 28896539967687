import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert/alert.service';
import { Observable } from 'rxjs';
import { Pagination } from '@app/shared/models/pagination';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PersonalizationService extends BaseService {

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    update(queryParams?): Observable<any> {

        return this.http
            .post<any>(`${this.api}/personalization/`, queryParams)
            .pipe(catchError(this.handleError<any[]>('update')));
    }

    updateStatus(queryParams?): Observable<any> {
        return this.http
            .post<any>(`${this.api}/personalization/status`, queryParams)
            .pipe(catchError(this.handleError<any[]>('update')));
    }

    get(
        id: number,
        queryParams?: {
            [key: string]: any;
        }
    ): Observable<any> {
        const defaultParams = {};
        console.log(id);
        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<any[]>(`${this.api}/personalization/${id}`, {
                responseType: 'json'
            });
        // .pipe(catchError(this.handleError<any>('get')));
    }

    updateWaveCode(queryParams?): Observable<any> {
        return this.http
            .post<any>(`${this.api}/personalization/wave`, queryParams)
            .pipe(catchError(this.handleError<any[]>('updateWaveCode')));
    }

    getChartData() {
        return this.http
            .get<any>(`${this.api}/personalization/chart`)
            .pipe(catchError(this.handleError<any>('getChartData')));
    }
}
