import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '../../alert/alert.service';
import { BaseService } from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportPickingService extends BaseService {

  constructor(public http: HttpClient, alertService: AlertService) {
    super(alertService, http);
  }

  downloadReportExcel(params: any) {
    this.downloadXLS(`${this.api}/picking/report/excel`, params, 'picking');
  }
}
