export class Token {
    private access_token: string;
    private expires_in: number;
    private expires_in_date: Date;
    private refresh_token: string;
    private token_type: string;

    constructor(token) {
        Object.assign(this, token);
        if (this.expires_in) {
            this.setExpiresInDate();
        }
    }

    setExpiresInDate() {
        if (this.expires_in && !this.expires_in_date) {
            this.expires_in_date = new Date(
                new Date().setSeconds(new Date().getSeconds() + this.expires_in)
            );
        }

        if (this.isString(this.expires_in_date)) {
            this.expires_in_date = new Date(this.expires_in_date);
        }
    }

    get isExpired() {
        return new Date() > this.expires_in_date;
    }

    get authorizationHeader() {
        return `${this.token_type} ${this.access_token}`;
    }

    isString(s) {
        return typeof s === 'string' || s instanceof String;
    }

    get refreshToken() {
        return this.refresh_token;
    }
}
