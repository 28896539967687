import { AlertService } from '@app/shared/services/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';

@Injectable({
    providedIn: 'root'
})
export class ReportStockService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

     downloadExcel( params: any) {
        this.downloadXLS(`${this.api}/integration/phx/report`, params, 'stock');
    }
}
