import { Router } from '@angular/router';
import { Customer } from './../../../../shared/models/customer';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-report-stock',
    templateUrl: './report-stock.component.html',
    styleUrls: ['./report-stock.component.scss']
})
export class ReportStockComponent implements OnInit {
    @Input() customers: Customer;
    @Output() exportExcel = new EventEmitter<any>();

    data: any = {};
    displayMonths = 1;

    constructor(private router: Router) {}

    ngOnInit() {}

    downloadXlsx() {
        const params = {
            customerCode: this.data.customer,
            dateFrom: this.toFormatDate(this.data.dateFrom),
            dateTo: this.toFormatDate(this.data.dateTo)
        };
        this.exportExcel.emit(params);
    }

    toFormatDate(date): String {
        const dateTime = new Date(date)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ');
        const [year, month, day, hour, minutes, seconds] = [
            ...dateTime.split(/[- :]/)
        ];
        return `${year}-${month}-${day}`;
    }

      backRouter() {
        this.router.navigate(['/reports/overview']);
    }
}
