import { Pagination } from '@app/shared/models/pagination';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-report-table',
    templateUrl: './report-table.component.html',
    styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit {
    @Input() table: any;

    displayedColumns: string[] = [''];

    dataSource: any;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor() {}

    ngOnInit() {
        this.start();
    }

    async start() {
        const handle = setInterval(() => {
            if (this.table) {
                const data = [];
                this.table.map((order: any) => {
                    data.push({});
                });
                this.dataSource = new MatTableDataSource<any>(data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                clearInterval(handle);
            }
        }, 200);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    toogleSearch() {
        document.querySelector('.barSearch').classList.toggle('opened');
        document
            .querySelector('.contentDetailsFilter')
            .classList.toggle('enabled');
    }
}
