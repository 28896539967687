import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Customer } from '../../models/customer';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    all(): Observable<Customer[]> {
        return this.http
            .get<Customer[]>(`${this.api}/customers`)
            .pipe(catchError(this.handleError<Customer[]>('all', [])));
    }

    getActive(): Observable<Customer[]> {
        return this.http
            .get<Customer[]>(`${this.api}/customers/active`)
            .pipe(catchError(this.handleError<Customer[]>('getActive')));
    }

    getCustomer(id): Observable<Customer> {
        return this.http
            .get<Customer>(`${this.api}/customers/${id}`)
            .pipe(catchError(this.handleError<Customer>(`getCustomer ${id}`)));
    }

    saveCustomer(customer): Observable<any> {
        return this.http
            .post<any>(`${this.api}/customers`, customer)
            .pipe(
                catchError(
                    this.handleError<any>(
                        `saveCustomer: ${customer.toString()}`
                    )
                )
            );
    }

    updateIntegrations(id: string, integrations): Observable<Customer> {
        return this.http
            .post<Customer>(
                `${this.api}/customers/${id}/integrations`,
                integrations
            )
            .pipe(catchError(this.handleError<Customer>(`updateIntegrations`)));
    }

    updateCustomer(customer): Observable<any> {
        return this.http
            .put<any>(`${this.api}/customers`, customer)
            .pipe(
                catchError(
                    this.handleError<any>(
                        `updateCustomer: ${customer.toString()}`
                    )
                )
            );
    }

    generateToken(id: string): Observable<Customer> {
        return this.http
            .get<Customer>(`${this.api}/customers/${id}/token`)
            .pipe(
                catchError(this.handleError<Customer>(`generateToken ${id}`))
            );
    }
}
