import {
    trigger,
    state,
    style,
    transition,
    animate
} from '@angular/animations';
import {
    Component,
    OnInit,
    NgZone,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import {
    Alert,
    AlertType,
    Icons,
    DefaultIcons
} from '@app/shared';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '@app/shared/services/alert/alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [
        trigger('enterLeave', [
            // Fade
            state('fade', style({ opacity: 1 })),
            transition('* => fade', [
                style({ opacity: 0 }),
                animate('400ms ease-in-out')
            ]),
            state('fadeOut', style({ opacity: 0 })),
            transition('fade => fadeOut', [
                style({ opacity: 1 }),
                animate('300ms ease-in-out')
            ]),

            // Enter from top
            state('fromTop', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('* => fromTop', [
                style({ opacity: 0, transform: 'translateY(-5%)' }),
                animate('400ms ease-in-out')
            ]),
            state(
                'fromTopOut',
                style({ opacity: 0, transform: 'translateY(5%)' })
            ),
            transition('fromTop => fromTopOut', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate('300ms ease-in-out')
            ]),

            // Enter from right
            state(
                'fromRight',
                style({ opacity: 1, transform: 'translateX(0)' })
            ),
            transition('* => fromRight', [
                style({ opacity: 0, transform: 'translateX(5%)' }),
                animate('400ms ease-in-out')
            ]),
            state(
                'fromRightOut',
                style({ opacity: 0, transform: 'translateX(-5%)' })
            ),
            transition('fromRight => fromRightOut', [
                style({ opacity: 1, transform: 'translateX(0)' }),
                animate('300ms ease-in-out')
            ]),

            // Enter from bottom
            state(
                'fromBottom',
                style({ opacity: 1, transform: 'translateY(0)' })
            ),
            transition('* => fromBottom', [
                style({ opacity: 0, transform: 'translateY(5%)' }),
                animate('400ms ease-in-out')
            ]),
            state(
                'fromBottomOut',
                style({ opacity: 0, transform: 'translateY(-5%)' })
            ),
            transition('fromBottom => fromBottomOut', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate('300ms ease-in-out')
            ]),

            // Enter from left
            state(
                'fromLeft',
                style({ opacity: 1, transform: 'translateX(0)' })
            ),
            transition('* => fromLeft', [
                style({ opacity: 0, transform: 'translateX(-5%)' }),
                animate('400ms ease-in-out')
            ]),
            state(
                'fromLeftOut',
                style({ opacity: 0, transform: 'translateX(5%)' })
            ),
            transition('fromLeft => fromLeftOut', [
                style({ opacity: 1, transform: 'translateX(0)' }),
                animate('300ms ease-in-out')
            ]),

            // Rotate
            state('scale', style({ opacity: 1, transform: 'scale(1)' })),
            transition('* => scale', [
                style({ opacity: 0, transform: 'scale(0)' }),
                animate('400ms ease-in-out')
            ]),
            state('scaleOut', style({ opacity: 0, transform: 'scale(0)' })),
            transition('scale => scaleOut', [
                style({ opacity: 1, transform: 'scale(1)' }),
                animate('400ms ease-in-out')
            ]),

            // Scale
            state('rotate', style({ opacity: 1, transform: 'rotate(0deg)' })),
            transition('* => rotate', [
                style({ opacity: 0, transform: 'rotate(5deg)' }),
                animate('400ms ease-in-out')
            ]),
            state(
                'rotateOut',
                style({ opacity: 0, transform: 'rotate(-5deg)' })
            ),
            transition('rotate => rotateOut', [
                style({ opacity: 1, transform: 'rotate(0deg)' }),
                animate('400ms ease-in-out')
            ])
        ])
    ]
})
export class AlertComponent implements OnInit {
    alerts: Alert[] = [];
    count = 0;
    speed = 0;
    timeOut = 3000;
    timer: any;
    start: any;
    steps: any;
    diff: any;

    constructor(
        private alertService: AlertService,
        private domSanitizer: DomSanitizer,
        private zone: NgZone
    ) {}

    ngOnInit() {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }

            // add alert to array
            this.alerts.unshift(alert);

            this.steps = (this.timeOut + this.alerts.length) / 2;
            this.speed = this.timeOut + this.steps * 1.2;
            this.start = new Date().getTime();
            setTimeout(() => {
                this.remove(alert);
            }, this.speed);
            // this.startTimeOut(alert);
        });
    }

    removeAlert(alert: Alert) {
        this.diff = new Date().getTime() - this.start - this.count * this.speed;
        if (this.count++ === this.steps) {
            this.remove(alert);
        } else {
            setTimeout(() => this.removeAlert(alert), this.speed - this.diff);
        }
    }

    remove(alert) {
        if (alert.animate) {
            alert.animate = alert.animate + 'Out';
            setTimeout(() => {
                this.alerts = this.alerts.filter(x => x !== alert);
            }, 610);
        } else {
            this.alerts = this.alerts.filter(x => x !== alert);
        }
    }

    startTimeOut(alert): void {}

    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }
        // this.count++;

        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                alert.icon = this.domSanitizer.bypassSecurityTrustHtml(
                    DefaultIcons.success
                );
                return 'alert alert-success';
            case AlertType.Error:
                alert.icon = this.domSanitizer.bypassSecurityTrustHtml(
                    DefaultIcons.error
                );
                return 'alert alert-danger';
            case AlertType.Info:
                alert.icon = this.domSanitizer.bypassSecurityTrustHtml(
                    DefaultIcons.info
                );
                return 'alert alert-info';
            case AlertType.Warning:
                alert.icon = this.domSanitizer.bypassSecurityTrustHtml(
                    DefaultIcons.warn
                );
                return 'alert alert-warning';
        }
        // let time = this.count  * this.speed;
        // this.timer = setTimeout(this.removeAlert(alert), time);
    }
}
