import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalLoadingService {

    loading$: Subject<boolean> = new Subject();

    constructor() { }

    startLoading() {
        this.loading$.next(true);
    }

    stopLoading() {
        this.loading$.next(false);
    }
}
