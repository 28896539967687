import { Role } from './role';

export class Account {
    _id?: string;
    id?: any;
    _email: string;
    _password: string;
    _active: boolean;
    _type: string;
    _role: Role | string;
    _createdAt?: string;
    _updatedAt?: string;

    constructor(parameters) {
        Object.assign(this, parameters);
    }

    set email(parameter: string) {
        this._email = parameter;
    }
    get email() {
        return this._email;
    }
    set password(parameter: string) {
        this._password = parameter;
    }
    get password() {
        return this._password;
    }
    set active(parameter: boolean) {
        this._active = parameter;
    }
    get active() {
        return this._active;
    }
    set role(parameter: any) {
        this._role = new Role(parameter);
    }
    get role() {
        return this._role;
    }
}
