import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AttachmentsService } from '@app/shared';
import { ReportsService } from '@app/shared/services/reports/reports.service';

@Component({
    templateUrl: './ftp-integrations.component.html',
    styleUrls: ['./ftp-integrations.component.scss']
})
export class FtpIntegrationsComponent implements OnInit {
    report$: any[];
    id: number;
    constructor(
        private _activateRoute: ActivatedRoute,
        private _ReportsService: ReportsService
    ) {
        this.id = this._activateRoute.snapshot.params['id'];

    }

    ngOnInit() {
        this._ReportsService.getAll().subscribe((report) => {
            this.report$ = report;
        });

    }
}
